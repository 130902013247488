<template>
  <el-dialog title="完善信息" :visible.sync="drawer" width="600px">
    <el-form ref="form" :model="entity" label-width="110px" :rules="rules">
      <el-form-item label="用户名" prop="UserName">
        <el-input v-model="entity.UserName" placeholder="用户名"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="RealName">
        <el-input v-model="entity.RealName" placeholder="姓名"></el-input>
      </el-form-item>
      <el-form-item label="手机号">
        <el-input disabled v-model="entity.UserPhone" placeholder="手机号"></el-input>
      </el-form-item>
      <!-- <el-form-item label="密码">
        <el-input
          v-model="entity.newPwd"
          type="password"
          placeholder="密码"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="性别">
        <el-radio v-model="entity.Sex" :label="0">女</el-radio>
        <el-radio v-model="entity.Sex" :label="1">男</el-radio>
      </el-form-item>
      <el-form-item label="出生日期" prop="Birthday">
        <el-date-picker
          style="width: 100%"
          v-model="entity.Birthday"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="上传头像" prop="UserAvatarURL">
        <UploaderAvatar v-model="entity.UserAvatarURL" />
      </el-form-item>
      <el-form-item label="同时为买家" prop="Type">
        <el-switch
          @change="switchChange"
          v-model="isSwitch"
          active-color="#D50700"
          inactive-color="#409EFF">
        </el-switch>
      </el-form-item>


    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import op from '@/utils/OperatorCache'
import UploaderAvatar from '@/components/UploaderAvatar.vue'
const regExp = new RegExp('^1[3456789]\\d{9}$') // 手机号正则
export default {
  props: {
    parentObj: { type: Object },
  },
  components: {
    UploaderAvatar,
  },
  data() {
    return {
      isSwitch:false,
      drawer: false,
      entity: {},
      rules: {
        UserName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        RealName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        UserPhone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请输入手机号'))
              } else if (!regExp.test(value)) {
                callback(new Error('请输入正确的手机号'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
        newPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        Sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
      },
      btnLoading: false,
      title: '',
      loading: false,
      timeout: null,
    }
  },
  methods: {
    // 同时为买家开关
    switchChange(e){
      
      if(e==true){
        this.entity.Type=2
      }else{
        this.entity.Type=0
      }
      console.log('四否为买家', e,this.entity.Type)
    },
    init() {
      this.entity = {}
      this.drawer = true
      if (this.$refs['form']) {
        this.$refs['form'].clearValidate()
      }
    },
    openForm(Id) {
      this.init()
      this.getTheData(Id)
    },
    handleClose(done) {
      done()
    },
    handleSelect(item) {
      console.log(item)
    },
    getTheData(id) {
      this.$http
        .post('/Base_Manage/Base_User/GetTheData', { id })
        .then((res) => {
          if (res.Success) {
            this.entity = res.Data
            if(this.entity.Type==2){
              this.isSwitch=true
            }else{
              this.isSwitch=false
            }
          } else {
            this.$message.error(res.Msg)
          }
        })
    },
    handleSubmit() {
      if(this.isSwitch==true){
        this.entity.Type=2
      }else{
        this.entity.Type=0
      }

      console.log('打印传递参数', this.entity, this.isSwitch)
      this.$refs['form'].validate((valid) => {
        if (!valid) {
          return
        }
        this.btnLoading = true
        this.$http
          .post('/Base_Manage/Base_User/SaveData', this.entity)
          .then((res) => {
            this.btnLoading = false
            if (res.Success) {
              this.$message.success('操作成功')
              op.updateInfo(() => {
                location.reload()
              })
              this.drawer = false
            } else {
              this.$message.error(res.Msg)
            }
          })
      })
    },
  },
}
</script>

<style></style>
